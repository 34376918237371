import { Box, Button, ButtonGroup, Stack } from '@mui/material'
import React, { forwardRef, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import AccountSetup from './AccountSetup'
import Company from './Company'
import ConsentApproval from './ConsentApproval'
import IntroduceYourself from './IntroduceYourself'
import Password from './Password'
import Welcome from './Welcome'
import Stepper from './Stepper'

const RenderComponent = props => {
  const [searchParams, setSearchParams] = useSearchParams({ token: null })
  const token = searchParams.get('token')
  const Components = {
    IntroduceYourself: {
      Component: IntroduceYourself,
      step: 0,
      label: 'Introduce Yourself!'
    },
    Company: {
      Component: Company,
      step: 1,
      label: 'Your Business'
    },
    Welcome: {
      Component: Welcome,
      step: 2,
      label: 'Welcome'
    }
  }
  
  const ComponentAliases = {
    0: { ...Components.IntroduceYourself },
    1: { ...Components.Company },
    2: { ...Components.Welcome }
  }
  const steps = Object.keys(Components).map((key) => {
    return {
      key: Components[key].step,
      label: Components[key].label
    }
  })

  // Decide what component we are using
  const [ component, setComponent ] = useState(ComponentAliases[0])
  let Component = component.Component;
  const [ label, setLabel ] = useState(component.label)
  const [ step, setStep ] = useState(component.step)

  useEffect(() => {
    setComponent(ComponentAliases[step])
    setLabel(ComponentAliases[step].label)
    Component = ComponentAliases[step].Component
  }, [step])

  const previousSlide = event => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const nextSlide = event => {
    if (step <= (steps.length - 1)) {
      setStep(step + 1)
    }
  }

  return (
    <Stack>
      <section id="slide-stepper">
        <Stepper steps={steps} step={step} label={label} />
      </section>
      <section className="slide-content">
        { component && <Component /> }
      </section>
      <section id="slide-navigation">
          { step > 0             && <Button id="back-button" onClick={previousSlide}>Back</Button> }
          { steps.length > (step + 1) && <Button id="next-button" onClick={nextSlide}>Next</Button> }
          { steps.length == (step + 1) && <Button id="finish-button" type="submit">Done!</Button> }
      </section>
    </Stack>
  )
}

const Component = (props) => {
  return (
    <Box id="slides-container">
      <RenderComponent />
    </Box>
  )
}

export default Component;