import React, { forwardRef, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { default as FormContainer } from '../../ui/Form/FormContainer'
import cloneDeep from 'lodash/cloneDeep'
import { Slides } from './slides'

const Embark = forwardRef((props, ref) => {
  const [ initialData, setInitialData ] = useState({})

  // useEffect(() => {
  //   // We do initialData stuff here
  //   setInitialData({}) 
  // }, [])

  const { onFormSubmit } = props
 
  return (
    <div id="embark-container">
      <Formik
        innerRef={ref}
        initialValues={{
          ...initialData
        }}
        onSubmit={(values, { setSubmitting }) => {
					const formData = cloneDeep(values)
					onFormSubmit?.(formData, setSubmitting)
        }}
      >
        {({ values, touched, errors, isSubmitting }) => 
          <Form>
            <FormContainer>
              <Slides touched={touched} errors={errors} values={values} />
            </FormContainer>
          </Form>
        }
      </Formik>
    </div>
  )
})

Embark.defaultProps = {
	initialData: {
		id: '',
		step: 0
	}
}

export default Embark