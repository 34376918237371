import logo from './logo-white.png'

function Header() {

  return (
    <header className="site-header">
        <img src={logo} className="header-logo" alt="SorceryWorks" />
    </header>
  )
}

export default Header