import React, { forwardRef, useState, useEffect } from 'react'
import { Field } from 'formik'
import { Box, Grid, Typography } from '@mui/material'
import FormItem from '../../../../ui/Form/FormItem'

const Company = (props) => {
  return (
    <Grid>
      <FormItem
        label="Business Name"
      >
        <Field
          type="text"
          autoComplete="off"
          name="business_name"
          placeholder="Your Company's Name"
        />
      </FormItem>
      <FormItem
        label="Describe your Business"
      >
        <Field
          type="text"
          autoComplete="off"
          name="product"
          placeholder="Example: eCommerce, SaaS, Car Dealer, etc."
        />
      </FormItem>
    </Grid>
  )
}

export default Company