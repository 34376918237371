import "./index.css"
import React, { forwardRef, useState, useEffect } from 'react'
import { Field } from 'formik'
import { Box, Checkbox, FormControlLabel, FormGroup, InputAdornment, TextField, Typography } from '@mui/material'
import FormItem from '../../../../ui/Form/FormItem'

const Welcome = (props) => {
  return (
    <Box>
      <FormItem
        label="What's your current budget for Digital Marketing?"
      >
        <TextField
          autoComplete="off"
          name="name"
          InputProps={{
            startAdornment: <InputAdornment id="marketing-budget" position="start"><span class="adornment-dollar">$</span></InputAdornment>,
          }}
          placeholder="5,000"
        />
      </FormItem>

      <FormItem
        label="What is your primary marketing strategy? (Check all that apply)"
      >
        <FormGroup name="current_strategies">
          <div className="col-6">
            <FormControlLabel control={<Checkbox value="social_media" />} label="Organic Social Media (Facebook, twitter, Instagram)" />
            <FormControlLabel control={<Checkbox value="facebook_ads" />}  label="Facebook Ads" />
            <FormControlLabel control={<Checkbox value="twitter_ads" />}  label="Twitter Ads" />
            <FormControlLabel control={<Checkbox value="google_ads" />}  label="Google Ads" />
            <FormControlLabel control={<Checkbox value="telemarketing" />}  label="Telemarketing" />
            <FormControlLabel control={<Checkbox value="email_marketing" />}  label="Email Marketing" />
          </div>
          <div className="col-6">
            <FormControlLabel control={<Checkbox value="door_to_door" />}  label="Door-To-Door" />
            <FormControlLabel control={<Checkbox value="youtube" />}  label="Youtube Ads" />
            <FormControlLabel control={<Checkbox value="youtube_channel" />}  label="Youtube Channel" />
            <FormControlLabel control={<Checkbox value="yelp_ads" />}  label="Yelp Ads" />
            <div className="other-strategy">
              <FormControlLabel control={<Checkbox />} label="Other" />
              <input 
                type="text" 
                placeholder="Other Marketing Strategy"
              />
            </div>
          </div>
        </FormGroup>
      </FormItem>
    </Box>
  )
}

export default Welcome