import React, { forwardRef, useState, useEffect } from 'react'
import { Field } from 'formik'
import { Box, Typography } from '@mui/material'
import FormItem from '../../../../ui/Form/FormItem'

const IntroduceYourself = (props) => {
  return (
    <Box id="introduceYourself-slide">
      <FormItem
        label="Who are you?"
      >
        <Field
          type="text"
          autoComplete="off"
          name="name"
          placeholder="Your Name"
        />
      </FormItem>
      <FormItem
        label="What's Your Email Address?"
      >
        <Field
          type="text"
          autoComplete="off"
          name="email"
          placeholder="your@email.com"
        />
      </FormItem>
    </Box>
  )
}

export default IntroduceYourself