import './App.css';

import React from 'react'
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom'
import history from './history'
import Embark from './components/Embark'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <Header /> 
      <BrowserRouter history={history}>
        <Routes>
          <Route path="*" element={ <Embark /> } />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
